import React, {Component, Fragment} from 'react';
import isEmpty from 'lodash.isempty';
import './LBDProposal.css';
import StaticMap from "./StaticMap";

// const LOGO_SRC = 'https://www.daisyoutdoor.com/wp-content/uploads/2020/06/Daisy-Outdoor-Advertising-Logo.png';
// const LOGO_ALT = 'Daisy Outdoor Advertising Co Inc';
// const CONTACT_EMAIL = 'kim@daisyoutdoor.com';
const LOGO_SRC = 'https://www.victoroutdoor.com/wp-content/uploads/2021/01/victor-compressed.png';
const LOGO_ALT = 'Victor Outdoor Advertising';
const CONTACT_EMAIL = 'ksteele@victoroutdoor.com';
const CONTACT_PHONE = '308.233.4257';

const EXCLUDE_FIELDS = [
  'structure_number',
  'latitude',
  'longitude',
  'image'
];

class LBDProposal extends Component {

  state = {
    placesByCounty: [],
    places: []
  };

  componentDidMount() {
    fetch('/wp-json/wp/v2/billboard?_per_page=300&order=asc&orderby=title')
        .then(response => response.json())
        .then(places => {

          places.forEach(place => {
            place.show = false;
          });

          // let csv = '';
          // const header = Object.keys(places[0].lbd);
          // csv += '"' + header.join('","') + '"\r\n';
          // places.forEach(place => {
          //   let fields = place.lbd;
          //   let row = [];
          //   header.forEach(key => {
          //     let {value} = (fields[key] || {value:''});
          //     if(typeof value === 'object') {
          //       value = value.id;
          //     }
          //     if(key === 'direction' && value.length > 0) {
          //       value = value[0];
          //     }
          //     row.push(value);
          //   });
          //   csv += '"' + row.join('","') + '"\r\n';
          // })
          // window.csv = csv;
          // window.places = places;

          let placesByCounty = {};
          places.forEach(place => {
            const county = place.lbd.county.value;
            if(typeof placesByCounty[county] === 'undefined') {
              placesByCounty[county] = [];
            }
            placesByCounty[county].push(place);
          });
          this.setState({placesByCounty, places});
        });
  }

  render() {
    const {places, placesByCounty} = this.state;

    if(isEmpty(places) || isEmpty(placesByCounty)) {
      return <p>Loading</p>;
    }

    return (
        <div className="lbd-proposal">
          {Object.keys(placesByCounty).sort().map(county => (
              <div key={county} className="lbd-proposal-options">
                <h2>{county}</h2>
                {placesByCounty[county].map(place => (
                    <button key={`${place.id}${place.lbd.structure_number.value}`} className={place.show ? 'active' : ''} onClick={() => {
                      this.setState(state => {
                        const places = [...state.places];
                        const index = places.indexOf(place);
                        place.show = !place.show;
                        places[index] = place;

                        return {places};
                      });

                    }}>{place.lbd.structure_number.value}</button>
                ))}
              </div>
          ))}
          <div className="lbd-page lbd-cover">
            <div>
              <img width="300" src={LOGO_SRC} alt={LOGO_ALT} />
              <hr/>
              <h1>Billboard Proposal</h1>
              {this.state.preparedBy && <p className="show-print">Prepared by: {this.state.preparedBy}</p>}
              <label htmlFor="preparedBy" className="hidden-print">Prepared By:</label>
              <input id="preparedBy" type="text" className="hidden-print" onChange={e => this.setState({preparedBy: e.target.value})} />
              {this.state.coverText && <p style={{maxWidth: '60%'}} className="show-print">{this.state.coverText}</p>}
              <label htmlFor="coverText" className="hidden-print">Cover Text:</label>
              <textarea id="coverText" className="hidden-print" onChange={e => this.setState({coverText: e.target.value})}></textarea>
            </div>
          </div>
          {places.filter(place => place.show).map(place => {

            const {latitude, longitude} = place.lbd;

            return (
              <div key={`${place.id}${place.lbd.structure_number.value}`} className="lbd-page">
                <header className="brand-color">
                  <div>
                    <img src={LOGO_SRC} alt={LOGO_ALT} />
                    <div className="contact-info">
                      {CONTACT_EMAIL && <div>Email: <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></div>}
                      {CONTACT_PHONE && <div>Phone: <a href={`tel:+${CONTACT_PHONE}`}>{CONTACT_PHONE}</a></div>}
                    </div>
                    <div className="unit">UNIT: {place.lbd.structure_number.value}</div>
                  </div>
                </header>
                {typeof place.lbd.image !== 'undefined'
                  ? <div className="proposal-unit-image" aria-label="Billboard By a Road" style={{backgroundImage: `url(${place.lbd.image.value.url})`}} />
                  : ''}
                <div className="proposal-details-container">
                  <div className="proposal-static-map">
                    <StaticMap lat={latitude.value} lng={longitude.value} />
                  </div>
                  <div className="proposal-details">
                    <h3 style={{marginBottom: '1rem'}}><u>Bulletin Details:</u></h3>
                    <table className="table">
                      <tbody>
                      {Object.keys(place.lbd).filter(key => EXCLUDE_FIELDS.indexOf(key) === -1).map(key => (
                          <Fragment key={key}>
                            {place.lbd[key].value !== '' && (
                              <tr>
                                <td><b>{place.lbd[key].label}</b></td>
                                <td>{place.lbd[key].value}</td>
                              </tr>
                            )}
                          </Fragment>
                      ))}
                      <tr>
                        <td><b>{latitude.label},{longitude.label}</b></td>
                        <td>{latitude.value},{longitude.value}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
    );
  }
}

export default LBDProposal;