// const marker_center = {
//   lat: 34.6463,
//   lng: -82.1526
// };

const marker_center = {
  lat: 41.4379005432129,
  lng: -97.317497253418
};

export default marker_center;