import React, {Fragment, useState} from "react";
import G_API from "./const/g_api";

function StaticMap(props) {
  const {lat, lng} = props;

  const [zoom, setZoom] = useState(16)

  const setZoomWithConstraints = zoomInt => {
    if(zoomInt > 5 && zoomInt < 21){
      setZoom(zoomInt);
    }
  };

  return (
    <Fragment>
      <img className="static-map"
           alt="Static Map with Marker"
           src={`https://maps.googleapis.com/maps/api/staticmap?format=JPEG&center=${lat}+${lng}&zoom=${zoom}&size=640x640&maptype=roadmap&markers=color:red%7Clabel:%7C${lat},${lng}&key=${G_API}`} />
           <div className="hidden-print">
             <button onClick={() => setZoomWithConstraints(zoom + 1)}>Zoom In <span aria-hidden={true}>+</span></button>
             <button onClick={() => setZoomWithConstraints(zoom - 1)}>Zoom Out <span aria-hidden={true}>-</span></button>
             {/*Current Zoom: {zoom}*/}
           </div>
    </Fragment>
  )
}

export default StaticMap;